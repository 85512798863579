<template>
    <div class="clientcabin clientcabin-welcome">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="5" xl="4">
                    <p class="subheadline py-md-4 text-center">
                        {{ customHeadline }}
                    </p>
                    <p class="text-h5 font-weight-bold text-center">
                        This Works For Amazon, Walmart, Ebay & 1000s of Local
                        Businesses…
                        <br />
                        And Will Work For You Too!
                    </p>
                    <ul class="text-subtitle-1">
                        <li>
                            Reach Buyers That Are Searching For The Products and
                            Services You Have To Offer Right Now
                        </li>
                        <li>
                            Gives You Long Term & Sustainable Traffic Growth
                            With Initial Exposure Coming Within 30 Days
                        </li>

                        <li>
                            Establishes Your Business In Key Areas The
                            Competition Overlooks With A Tailored Done For You
                            Method & Improves Your Online Reputation Further
                            Increasing Sales
                        </li>
                    </ul>
                    <div v-if="!hasCustomForm" class="text-center pt-8">
                        <v-btn
                            :large="$vuetify.breakpoint.mdAndUp"
                            class="primary"
                            to="/proposal"
                        >
                            Click Here To Learn More
                        </v-btn>
                    </div>
                </v-col>
                <v-col
                    v-if="hasCustomForm"
                    cols="12"
                    lg="4"
                    xl="3"
                    class="text-center"
                >
                    <p class="text-h5 font-weight-bold pt-6">
                        Get More Details & Pricing For Our Organic DFY Traffic
                        Options:
                    </p>
                    <crm-provider id="optinform" :options="options?.options" />
                </v-col>
                <v-col
                    v-else
                    cols="12"
                    lg="4"
                    xl="3"
                    class="d-flex align-center justify-center"
                >
                    <v-img
                        src="@/assets/img/clientcabin/no-form-placeholder.png"
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="customVideo" class="pb-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <external-video-embed :video="customVideo" />
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container class="py-md-12">
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="text-center">
                        <p class="text-h4 font-weight-bold">
                            Traffic & Sales Success Story
                        </p>
                        <span class="text-subtitle-1">
                            In 1 year a medical device brand increased traffic
                            by approximately 20,000%. This has taken them from
                            approximately $48,000 in sales per month, to
                            $735,000 in sales per month. This is an increase of
                            $8,300,000 in yearly sales.
                        </span>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="d-flex align-center justify-center"
                    >
                        <v-img
                            eager
                            src="@/assets/img/clientcabin/proposal/GA Traffic (dark).png"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="pa-0">
                    <v-container class="faq py-md-8">
                        <v-row>
                            <v-col class="text-center">
                                <p class="subheadline">
                                    Frequently Asked Questions
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col
                                :class="{
                                    'mb-n6': $vuetify.breakpoint.smAndDown
                                }"
                                cols="12"
                                md="6"
                            >
                                <v-expansion-panels
                                    v-for="(faq, index) in faq1"
                                    :key="index"
                                    flat
                                    class="mb-4"
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                            class="font-weight-medium text-subtitle-1"
                                        >
                                            {{ faq.question }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content
                                            class="text-body-2"
                                        >
                                            <!-- eslint-disable vue/no-v-html -->
                                            <span
                                                class="black--text"
                                                v-html="faq.answer"
                                            ></span>
                                            <!-- eslint-enable -->
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-expansion-panels
                                    v-for="(faq, index) in faq2"
                                    :key="index"
                                    flat
                                    class="mb-4"
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                            class="font-weight-medium text-subtitle-1"
                                        >
                                            {{ faq.question }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content
                                            class="text-body-2"
                                        >
                                            <!-- eslint-disable vue/no-v-html -->
                                            <span
                                                class="black--text"
                                                v-html="faq.answer"
                                            ></span>
                                            <!-- eslint-enable -->
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="pb-md-8 pt-0">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="text-center">
                    <v-btn
                        v-if="hasCustomForm"
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="primary"
                        @click="scrollToForm"
                    >
                        Enter Your Details Now To Learn More
                    </v-btn>
                    <v-btn
                        v-else
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="primary"
                        to="/proposal"
                    >
                        Learn More
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { CrmProvider } from '@/components/ClientCabin/CRM';
import { ExternalVideoEmbed } from '@/components/ExternalVideoEmbed';

import type { ClientCabin, ClientCabinOptions } from '@/types/ClientCabin';
import { getScrollParent } from '@/utils/helpers';

@Component({
    components: {
        CrmProvider,
        ExternalVideoEmbed
    }
})
export default class Info extends Vue {
    shouldShowForm = false;

    @InjectReactive({
        from: 'options',
        default() {
            return null;
        }
    })
    options!: Partial<ClientCabin> | null;

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    faq1 = [
        {
            question: 'What is this traffic source and how does it work?',
            answer: 'Enter your email above and we will send you all the details, pricing, and a breakdown of the strategy.'
        },
        {
            question: 'Can this help me make more sales?',
            answer: 'Yes.  The beauty of this method is that people are already searching for what you have, and this is just about getting in front of them at the right time.'
        },
        {
            question: 'How does this impact my overall online presence?',
            answer: 'Beyond traffic, you’ll see improvements across the board - from brand awareness to customer trust.  This ultimately leads to more sales for your business.'
        }
    ];

    faq2 = [
        {
            question: 'Do I need technical expertise to benefit from this?',
            answer: 'No.  This is designed to have everything done for you.  You can optionally give information if you choose to.'
        },
        {
            question: 'Will this work for local businesses?',
            answer: 'Absolutely!  In fact, local businesses often see some of the most dramatic results.  It’s a true benefit for transforming local presences.'
        },
        {
            question: 'How quickly can I see results?',
            answer: 'Exposure can start happening in 7-10 days and you could start seeing noticeable results in traffic within the first 30-90 days, but this is also about long term ongoing free organic traffic growth.'
        }
    ];

    get customVideo() {
        return this.options?.options?.video_url;
    }

    get customHeadline() {
        return (
            this.options?.options?.custom_headline_short ||
            this.defaults.custom_headline_short
        );
    }

    get hasCustomForm() {
        return Boolean(
            this.options?.options?.default_crm &&
                this.options?.options?.crm[this.options?.options?.default_crm]
        );
    }

    scrollToForm() {
        const element = document.getElementById('optinform');

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.clientcabin-welcome::v-deep {
    background-color: $white;
    color: $black;

    .headline {
        font-size: 48px !important;
        line-height: 60px !important;
        letter-spacing: 0.02em;
        font-weight: 700;
        color: #282b30;
    }

    .subheadline {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: 0.035em;
        font-weight: 700;
        color: #282b30;
    }

    .subtitle {
        font-size: 20px;
        line-height: 36px;
    }

    .faq {
        .v-expansion-panels {
            border: 1px solid $tertiary-color;
            border-radius: 9px;

            .v-expansion-panel {
                background-color: transparent;
            }
        }

        .v-expansion-panel-header {
            color: $black !important;

            .v-expansion-panel-header__icon svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
